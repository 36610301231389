<template>
    <main class="" id="main" :class="{ 'container-slim': lgAndUp || xlAndUp }">
        <div class="container-fluid">
            <div class="row  justify-content-between ">
                <div class="col-md-8 col-10 mb-3 px-0 input-bg">
                    <el-input v-model="searchInput" class="w-100  " size="large"
                        placeholder="Search Names, IDs, Payments, Events etc" :prefix-icon="Search" />
                </div>
                <div class="col-md-3 ">
                    <div class="font-weight-bold h5">Portal by</div>
                    <img src="../../../assets/social/Logo.png" class="memberly-logo " alt="">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12 px-0 mt-4">
                    <el-button color="#F8F7F3" style="color: #1FBEA2; font-size: 21px;" class=""> Events </el-button>
                </div>

                <div class="col-md-12 mt-3 px-0">
                    <div class="row">
                        <div class="col-md-12  h4 font-weight-bold mt-4"> Upcoming</div>
                        <div class="col-md-4 ">
                            <img src="../../../assets/eventicon/mira.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">Around the World in 80 Days Challenge</div>
                            <div class="  mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4 mt-5 mt-md-0 ">
                            <img src="../../../assets/eventicon/reunion.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">FGC Class ‘89 Reunion Party 2024</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4  mt-5 mt-md-0 cursor-pointer" @click="experience">
                            <img src="../../../assets/eventicon/verad.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">The Experience Networking Concert</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12 font-weight-bold  h4 mt-4"> Other Events</div>
                        <div class="col-md-4 ">
                            <img src="../../../assets/eventicon/worldchallenge.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">Around the World in 80 Days Challenge</div>
                            <div class="  mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4  mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/summer.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">FGC Class ‘89 Reunion Party 2024</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4  mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/fitness.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">The Experience Networking Concert</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <!-- <div class="col-md-12  h3 mt-4"> Upcoming</div> -->
                        <div class="col-md-4  mt-5 mt-md-0 ">
                            <img src="../../../assets/eventicon/biofood.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">Around the World in 80 Days Challenge</div>
                            <div class="  mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4  mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/reunion.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">FGC Class ‘89 Reunion Party 2024</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4  mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/freedom.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">The Experience Networking Concert</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <!-- <div class="col-md-12  h3 mt-4"> Upcoming</div> -->
                        <div class="col-md-4  mt-5 mt-md-0 ">
                            <img src="../../../assets/eventicon/worldchallenge.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">Around the World in 80 Days Challenge</div>
                            <div class="  mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4  mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/summer.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">FGC Class ‘89 Reunion Party 2024</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4  mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/fitness.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">The Experience Networking Concert</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <!-- <div class="col-md-12  h3 mt-4"> Upcoming</div> -->
                        <div class="col-md-4   mt-5 mt-md-0 ">
                            <img src="../../../assets/eventicon/biofood.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">Around the World in 80 Days Challenge</div>
                            <div class="  mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4 mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/reunion.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">FGC Class ‘89 Reunion Party 2024</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4  mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/freedom.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">The Experience Networking Concert</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-4 mt-5 mt-md-0 ">
                            <img src="../../../assets/eventicon/mira.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">Around the World in 80 Days Challenge</div>
                            <div class="  mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4 mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/reunion.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">FGC Class ‘89 Reunion Party 2024</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                        <div class="col-md-4  mt-5 mt-md-0">
                            <img src="../../../assets/eventicon/verad.png" class="w-100" alt="">
                            <div class="font-weight-bold mt-4">The Experience Networking Concert</div>
                            <div class=" mt-2">Start 7th January 2024</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { Search } from '@element-plus/icons-vue'
import router from "../../../router/index";
import deviceBreakpoint from "../../../mixins/deviceBreakpoint";
export default {
    setup() {
        const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint();
        const searchInput = ref('')

        const experience = () =>{
            router.push('/portal/oneevent')
        }

        return {
            searchInput,
            experience,
            mdAndUp, lgAndUp, xlAndUp,
            Search
        }
    },
}
</script>

<style scoped>
.memberly-logo {
    width: 150px;
}
</style>