<template>
    <main class="" id="main" :class="{ 'container-slim': lgAndUp || xlAndUp }">
        <div class="container-fluid mt-4">
            <div class="row mt-4  justify-content-between ">
                <div class="col-md-8 col-10 mb-3 px-0 input-bg">
                    <el-input v-model="searchInput" class="w-100  " size="large"
                        placeholder="Search Names, IDs, Payments, Events etc" :prefix-icon="Search" />
                </div>
                <div class="col-md-3 ">
                    <div class="font-weight-bold h5">Portal by</div>
                    <img src="../../assets/social/Logo.png" class="memberly-logo " alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-md-7 mt-3  px-0 d-flex">
                    <div class="col-md-4"><el-button size="large" class="text-white w-100 " color="#2DBF82">
                            Resources</el-button></div>
                    <div class="col-md-4"><el-button size="large" class=" w-100  " color="#F8F7F3"> Downloads</el-button>
                    </div>
                    <div class="col-md-4"><el-button size="large" class=" w-100 text-color " color="#F8F7F3">
                            Bookmarked</el-button></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-4 d-flex align-items-center">
                    <div class="font-weight-bold"> Newly added</div>
                    <div>
                        <el-select v-model="selectResource" class="m-2" placeholder="Sort by">
                            <el-option v-for="item in newlyResource" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-8">
                    <div class="col-md-12 border pb-3 mt-4" style="border-radius: 0.5rem;  background: #F8F7F3;">
                        <div class="row justify-content-center">
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0 cursor-pointer" @click="lawPower"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 48 Laws of Power </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/play.png" alt=""> </div>
                                <div class="font-weight-bold"> Paris Vacation 2023 Highlight </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 48 Laws of Power </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/play.png" alt=""> </div>
                                <div class="font-weight-bold"> Paris Vacation 2023 Highlight </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 48 Laws of Power </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/play.png" alt=""> </div>
                                <div class="font-weight-bold"> Paris Vacation 2023 Highlight </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 1948 - Ignorance Freedom </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/play.png" alt=""> </div>
                                <div class="font-weight-bold"> Paris Vacation 2023 Highlight </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 1948 - Ignorance Freedom </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/play.png" alt=""> </div>
                                <div class="font-weight-bold"> Paris Vacation 2023 Highlight </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 1948 - Ignorance Freedom </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/play.png" alt=""> </div>
                                <div class="font-weight-bold"> Paris Vacation 2023 Highlight </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 1948 - Ignorance Freedom </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/play.png" alt=""> </div>
                                <div class="font-weight-bold"> Paris Vacation 2023 Highlight </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 1948 - Ignorance Freedom </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="col-md-12 px-0 font-weight-bold  h5">Downloads</div>
                    <div class="col-md-12 rounded border pb-3 mt-2">
                        <div class="row  justify-content-center">
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 d-flex justify-content-center ">
                                <div>see more</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 font-weight-bold px-0 mt-4 h5">Bookmarked</div>
                    <div class="col-md-12 rounded border pb-3 mt-2">
                        <div class="row  justify-content-center">
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 d-flex justify-content-center ">
                                <div>see more</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { Search } from '@element-plus/icons-vue'
import router from "../../router/index";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
export default {
    setup() {

        const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint();
        const searchInput = ref('')
        const selectResource = ref('')

        const lawPower = () => {
            router.push('/portal/oneresource')
        }

        const newlyResource = ref([
            {
                value: '1',
                label: 'Date',
            },
            {
                value: '2',
                label: 'number',
            },
            {
                value: '3',
                label: 'Name',
            },
        ])


        return {
            searchInput,
            Search,
            selectResource,
            mdAndUp, lgAndUp, xlAndUp,
            newlyResource,
            lawPower
        }

    },
}
</script>

<style scoped>
.memberly-logo {
    width: 150px;
}
</style>