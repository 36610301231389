<template>
    <main class="" id="main" :class="{ 'container-slim': lgAndUp || xlAndUp }">
        <div class="container-fluid mt-4">
            <div class="row">
                <div class="col-md-12">
                    <el-button color="#F8F7F3" class="text-secondary cursor-pointer" @click="previous"> <el-icon :size="26">
                            <Back />
                        </el-icon>Previous</el-button>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-8 mt-3 ">
                    <div class="row">
                        <div class="font-weight-bold col-md-12">The Experience Networking Concert</div>
                        <div class="col-md-12">Starts 7th January 2024</div>
                        <div class="col-md-12 mt-3">
                            <img src="../../../assets/eventicon/verad.png" class="w-100" alt="">
                        </div>
                        <div class="col-md-12 d-flex mt-4 justify-content-between">
                            <el-button color="#118DFF" size="large" class="text-white w-100">Register Now</el-button>
                            <el-button class="w-100" size="large"><el-icon :size="23" >
                                    <CollectionTag />
                                </el-icon>Bookmark</el-button>
                        </div>
                        <div class="col-md-12 mt-4">
                            Embark on a thrilling and adventurous journey as we bring to you the "Around the World in 80
                            Days Challenge." Inspired by the classic tale of Phileas Fogg's circumnavigation, this event is
                            a unique and exhilarating experience designed to test your creativity, resilience, and
                            problem-solving skills.

                        </div>
                        <div class="col-md-12 mt-4">
                            Date and Time: The challenge kicks off on 7th January and concludes precisely 80 days later on
                            7th March. Participants can begin their journey at any time within the specified start date.



                        </div>
                        <div class="col-md-12 mt-4">
                            Objective: Participants are tasked with virtually traveling around the world within 80 days,
                            just like the iconic journey undertaken by Phileas Fogg in Jules Verne's novel. However, unlike
                            Fogg's fictional journey, this challenge allows you to explore real-world locations, cultures,
                            and historical landmarks through a series of engaging and diverse activities.

                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3 ">
                    <div class="col-md-12 font-weight-bold mt-4 h5">Others Events</div>
                    <div class="col-md-12 rounded border pb-3 mt-2">
                        <div class="row  justify-content-center">
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../../assets/global.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../../assets/summer.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../../assets/global.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../../assets/summer.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../../assets/global.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../../assets/summer.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../../assets/summer.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 font-weight-bold mt-4 h5">Bookmarked</div>
                    <div class="col-md-12 rounded border pb-3 mt-2">
                        <div class="row  justify-content-center">
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../../assets/global.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../../assets/summer.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 d-flex justify-content-center ">
                                <div>see more</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12   mt-5 explore text-white">
                        <div class="row">
                            <div class="col-md-12 "> <img class="mt-3" src="../../../assets/dashboardicon/exploreimage.png"
                                    alt="">
                            </div>
                            <div class="col-md-9 ml-2 h5" style="font-weight: 550;">Memberly Ai Models Help you
                                manage
                                your Data
                                Well,
                                Need
                                Help?</div>
                            <div class="col-md-12 mb-4 d-flex justify-content-end">
                                <el-button class="text-white h5" size="large" color="#91D10A" round>Explore
                                    now!</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import router from "../../../router/index";
import deviceBreakpoint from "../../../mixins/deviceBreakpoint";
export default {
    setup() {

        const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint();

        const previous = () => {
            router.push('/portal/portalevent')
        }

        return {
            mdAndUp, lgAndUp, xlAndUp,
            previous
        }
    },
}
</script>

<style scoped>
.explore {
    background: linear-gradient(180deg, #58C492 0%, #4E876D 100%);
    border-radius: 0.5rem;

}
</style>